import { motion } from "framer-motion";
import { useState } from "react";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../utils/opacityAnimation";
import React from "react";

const ESG01 = () => {
  return (
    <div className="section esg__section01">
      <div className="esg__section01__content">
        <div className="esg__section01__content__top">
          <HorizontalMoveAnimation
            element="h4"
            delay={0.1}
            duration={0.3}
            direction="left"
          >
            <strong>E</strong>nvironmental
          </HorizontalMoveAnimation>
          <HorizontalMoveAnimation
            element="h4"
            delay={0.3}
            duration={0.3}
            direction="left"
          >
            <strong>S</strong>ocial <strong>G</strong>overnance
          </HorizontalMoveAnimation>
        </div>
        <OpacityAnimation
          className="esg__section01__content__bottom"
          element={"div"}
          delay={0.2}
          duration={1}
        >
          <span>카사요</span>
          <h3>자동차 쇼핑 통합 플랫폼</h3>
          <p>
            <span>
              우리 회사는 지속 가능한 미래를 위해 ESG 경영을 적극 실천하고
              있으며,
            </span>
            <span>
              이를 통해 환경, 사회, 그리고 지배구조 면에서 모범적인 기업이
              되고자 노력하고 있습니다.
            </span>
          </p>
        </OpacityAnimation>
      </div>
    </div>
  );
};
export default ESG01;
