import { motion, useAnimation } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CarsayoContentWrap from "../layout/content";
import CarsayoMobileWrap from "../layout/mobileWrap";
import OSCheck from "../components/OSCheck";
import PremiumEvent from "./PremiumEvent";
import CarsayoPromotionPopup from "../components/CarsayoPromotionPopup";
import CarsayoFileUpload from "../components/CarsayoFileUpload";
import PremiumEventMobile from "./PremiumEventMobile";
import CarsayoContentWrapV2 from "../layout/content_v2";

const PromotionPage = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isPopupOpen, setPopupOpen] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" ? (
        <CarsayoMobileWrap hiddenHeader={true}>
          <PremiumEventMobile />
        </CarsayoMobileWrap>
      ) : isMobile ? (
        <CarsayoMobileWrap hiddenHeader={true}>
          <PremiumEventMobile />
        </CarsayoMobileWrap>
      ) : (
        <CarsayoContentWrapV2>
          <PremiumEvent />
        </CarsayoContentWrapV2>
      )}
    </>
  );
};
export default PromotionPage;
