import { useState } from "react";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { motion } from "framer-motion";
import TypingAnimationText from "../components/TypingAnimationText";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import React from "react";
import { useNavigate } from "react-router-dom";

interface MainInterface {
  currentOS: string;
}

const MainSection03 = ({ currentOS }: MainInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="section wrap">
      <div className="main-section03">
        <div className="left">
          <div>
            <VerticalMoveAnimation
              element="div"
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
            >
              <h3>
                <span>신차 구매와 내 차 팔기</span>
                <span>자동차 보험까지 한번에</span>
              </h3>
              <span className={isAnimated ? "animation" : ""}>
                <UnderlineSketch fill="#FFE492" />
              </span>
            </VerticalMoveAnimation>
          </div>
          <div>
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
              style={{ display: "inline-block" }}
            >
              <span>
                자동차 관련 폭 넓은 서비스를 제공하여 손품 팔던 불편함을
                해소하고 정보의 비대칭을 해결합니다.
              </span>
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="left"
              style={{ display: "inline-block" }}
            >
              <span>
                신차 구매, 내 차 팔기, 자동차 금융, 자동차 다이렉트 보험 등
                자동차 관련 모든 서비스를 제공하고 있습니다.
              </span>
            </HorizontalMoveAnimation>
          </div>
          <CarsayoButton
            bgColor={"#4F9CF9"}
            onIcon="../imgs/svg/rightArrow.svg"
            iconSize=".875rem"
            padding="1.25rem 2.5rem"
            onClick={() => {
              currentOS === "iOS" || currentOS === "Mac"
                ? window.open(
                    `https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`
                  )
                : window.open(
                    `https://play.google.com/store/apps/details?id=com.ksapp.carsayo`
                  );
            }}
          >
            확인하기
          </CarsayoButton>
        </div>
        <div className="right">
          <HorizontalMoveAnimation
            element="div"
            delay={0.2}
            duration={0.5}
            direction="right"
            style={{ display: "inline-block" }}
          >
            <img src="../imgs/png/mockup.png" alt="" />
          </HorizontalMoveAnimation>
        </div>
      </div>
    </div>
  );
};

export default MainSection03;
