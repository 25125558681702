import { useNavigate } from "react-router-dom";
import Expression from "../components/svg/Expression";
import { useEffect, useState } from "react";

const PremiumEvent = () => {
  const navigate = useNavigate();
  const [iframeLocation, setIframeLocation] = useState(
    "https://app.carsayo.net"
  );

  useEffect(() => {
    console.info("iframe 확인용   ----   ", iframeLocation);
  }, [iframeLocation]);
  return (
    <div className="section premiumEvent">
      <div style={{ overflowY: "hidden" }}>
        <div className="left">
          <div className="promotion-contents">
            <div className="promotion-contents__box">
              <strong>premium service</strong>
              <h3>
                <span>CAR</span>
                <span>HEROES RISE</span>
              </h3>
              <p>
                <span>견적받기부터 시작되는 복잡한 차량 구매 절차</span>
                <span>결제 방식에 따른 이자 계산, 보험, 사후관리까지 </span>
                <span>더 이상 두고 볼수만은 없기에</span>
                <span>
                  자신만의 전문성을 살려 최고의 서비스를 제공하는 카사요
                  히어로즈를 만나보세요
                </span>
              </p>
              <Expression
                width="169"
                height="83"
                fill="#FF5B79"
                text="히어로즈 알아보기"
                clickEvent={() => navigate("/company/character")}
              />
            </div>
            <nav className="promotion-contents__nav">
              <ul>
                <li
                  style={{
                    backgroundImage: "url(../imgs/svg/promotion-menu-bg02.svg)",
                  }}
                  onClick={() => {
                    setIframeLocation("https://app.carsayo.net");
                    setTimeout(() => {
                      setIframeLocation(
                        "https://app.carsayo.net/static/insurance"
                      );
                    }, 0);
                  }}
                >
                  <button>#1. 다이렉트 보험</button>
                </li>
                <li
                  style={{
                    backgroundImage: "url(../imgs/svg/promotion-menu-bg01.svg)",
                  }}
                >
                  <button>#2. Comming Soon</button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="promotion-decoration">
            <img
              src="../imgs/png/decoration01.png"
              alt="프로모션 데코이미지01"
            />
            <img
              src="../imgs/png/decoration02.png"
              alt="프로모션 데코이미지02"
            />
            <img
              src="../imgs/svg/character_section05_img03.svg"
              alt="카사요히어로즈 이미지"
            />
          </div>
        </div>
        <div className="right">
          <iframe
            src={iframeLocation}
            width="100%"
            height="100%"
            style={{
              border: "none",
              overflowY: "hidden",
              scrollbarWidth: "none",
            }}
            title="Carasyo App"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default PremiumEvent;
