import { motion, useAnimation } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CarsayoHeader from "../layout/header";
import CarsayoFooter from "../layout/footer";
import CCM01 from "./section01";
import CCM02 from "./section02";
import CCM03 from "./section03";
import CarsayoContentWrap from "../layout/content";
import CarsayoMobileWrap from "../layout/mobileWrap";
import CarsayoMobileCCM from "./ccmMobile";
import OSCheck from "../components/OSCheck";
import CarsayoContentWrapV2 from "../layout/content_v2";

const CCManagement = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" ? (
        <CarsayoMobileWrap>
          <CarsayoMobileCCM />
        </CarsayoMobileWrap>
      ) : isMobile ? (
        <CarsayoMobileWrap>
          <CarsayoMobileCCM />
        </CarsayoMobileWrap>
      ) : (
        <CarsayoContentWrapV2>
          <CCM01 />
          <CCM02 />
          <CCM03 />
        </CarsayoContentWrapV2>
      )}
    </>
  );
};
export default CCManagement;
