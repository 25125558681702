import { motion } from "framer-motion";
import { useState } from "react";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import React from "react";

const ESG02 = () => {
  const [isActive, setActive] = useState("carrier");

  const handleClickEvents = {
    menu: (menu: string) => () => {
      setActive(menu);
    },
  };

  return (
    <div className="section esg__section02">
      <div className="company__section__title">
        <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
          카사요 ESG 경영전략
          <span>ESG</span>
        </VerticalMoveAnimation>
      </div>
      <div className="esg__section02__content">
        <ul>
          <HorizontalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="left"
          >
            <div>
              <h4>환경안전 경영</h4>
            </div>
            <div>
              <span>환경안전 경영 지속 가능한 경영</span>
              <p>
                을 위해 환경경영시스템(ISO14001)을 취득하였으며 환경 및 안전
                법규를 준수하여 사고 예방 활동에 전 구성원이 적극 참여하고
                있습니다.
              </p>
            </div>
          </HorizontalMoveAnimation>
          <HorizontalMoveAnimation
            element="li"
            delay={0.4}
            duration={0.5}
            direction="left"
          >
            <div>
              <h4>사회책임 경영</h4>
            </div>
            <div>
              <span>우리는 다양한 사회공헌 활동</span>
              <p>
                을 통해 지역사회와의 상생을 도모하며 공정한 거래와 윤리적 경영을
                통해 사회적 책임을 다하고 있습니다.
              </p>
            </div>
          </HorizontalMoveAnimation>
          <HorizontalMoveAnimation
            element="li"
            delay={0.6}
            duration={0.5}
            direction="left"
          >
            <div>
              <h4>지배구조 경영</h4>
            </div>
            <div>
              <span>공정하고 투명한 경영</span>
              <p>
                을 위해 지속적으로 노력하고 있습니다. 신뢰를 바탕으로 한 투명한
                의사결정을 통해 지속 가능한 성장을 추구합니다.
              </p>
            </div>
          </HorizontalMoveAnimation>
        </ul>
      </div>
    </div>
  );
};
export default ESG02;
