import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../utils/opacityAnimation";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import UnderlineSketch from "../components/svg/UnderlineSketch";

const CarsayoMobileCCM = () => {
  // sectioin01 - 소비자중심경영
  const Section01 = () => {
    return (
      <section
        className="m-ccm01"
        style={{
          background: "url('../imgs/svg/ccm-bg.svg') no-repeat top right",
        }}
      >
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title"
        >
          소비자중심경영
        </VerticalMoveAnimation>
        <div>
          <OpacityAnimation element="p" delay={0.1} duration={1.5}>
            <span>카사요는</span>
            <span>소비자 중심 경영(CCM)을 지향하며</span>
            <span>고객의 필요와 요구를 최우선으로 고려하는</span>
            <span>경영방식을 채택하고 있습니다.</span>
          </OpacityAnimation>
        </div>
        <HorizontalMoveAnimation
          element="p"
          delay={0.3}
          duration={0.5}
          direction="left"
        >
          <span>고객의 피드백을</span>
          <span>적극적으로 수용하고 이를 바탕으로 </span>
          <span>제품과 서비스를 개선하여 신뢰를 구축하는데 </span>
          <span>최선을 다하고 있습니다.</span>
        </HorizontalMoveAnimation>
      </section>
    );
  };
  // section02 - 첫번째 섹션
  const Section02 = () => {
    return (
      <section className="m-ccm02">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
          style={{ letterSpacing: "11.2px", marginLeft: "2.8px" }}
        >
          소비자중심경영
        </VerticalMoveAnimation>
        <VerticalMoveAnimation
          element="p"
          delay={0.3}
          duration={0.5}
          direction="top"
        >
          <span>기업이 수행하는 모든 활동을</span>
          <span>소비자 관점에서 소비자 중심으로 구성하고</span>
          <span>관련 경영 활동을 지속적으로 개선하고 있는지를</span>
          <span>한국 소비자 보호원이 평가하고</span>
          <span>공정거래 위원회가 인증하는 제도입니다.</span>
        </VerticalMoveAnimation>
        <OpacityAnimation element="div" delay={0.3} duration={2}>
          <img
            src="../imgs/svg/ccm-li-img01.svg"
            alt="소비자중심경영설명이미지"
          />
        </OpacityAnimation>
      </section>
    );
  };
  // section03 - 중간섹션
  const Section03 = () => {
    return (
      <section className="m-ccm03">
        <VerticalMoveAnimation
          element="p"
          delay={0.2}
          duration={0.5}
          direction="top"
        >
          <span>카사요는</span>
          <span>소비자 중심경영(CCM)을 지향하여</span>
          <span>고객의 피드백을 적극적으로 수용하고</span>
          <span>이를 바탕으로 제품과 서비스를 개선하여</span>
          <span>신뢰를 구축하는데 최선을 다하고 있습니다.</span>
        </VerticalMoveAnimation>
        <ul>
          <OpacityAnimation
            element="li"
            className="core"
            delay={0.1}
            duration={1.5}
          >
            <HorizontalMoveAnimation
              element="h3"
              delay={0.2}
              duration={0.5}
              direction="left"
            >
              1. 중점사업
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="ul"
              delay={0.3}
              duration={0.5}
              direction="left"
            >
              <li>
                <div>
                  <img
                    src="../imgs/svg/ccm-core-icon01.svg"
                    alt="중점사업아이콘01"
                  />
                </div>
                <p>자동차 쇼핑 통합 플랫폼 제공</p>
              </li>
              <li>
                <div>
                  <img
                    src="../imgs/svg/ccm-core-icon02.svg"
                    alt="중점사업아이콘02"
                  />
                </div>
                <p>혁신적인 R&D</p>
              </li>
              <li>
                <div>
                  <img
                    src="../imgs/svg/ccm-core-icon03.svg"
                    alt="중점사업아이콘03"
                  />
                </div>
                <p>소비자 친화적 조직 문화</p>
              </li>
              <li>
                <div>
                  <img
                    src="../imgs/svg/ccm-core-icon04.svg"
                    alt="중점사업아이콘04"
                  />
                </div>
                <p>자동차 금융 서비스 </p>
              </li>
            </HorizontalMoveAnimation>
          </OpacityAnimation>
          <OpacityAnimation
            element="li"
            className="value"
            delay={0.1}
            duration={1.5}
          >
            <HorizontalMoveAnimation
              element="h3"
              delay={0.2}
              duration={0.5}
              direction="left"
            >
              2. 핵심가치
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="ul"
              delay={0.3}
              duration={0.5}
              direction="left"
            >
              <li
                style={{
                  background:
                    "url('../imgs/svg/ccm-value-img01.svg') no-repeat center",
                }}
              >
                <span>소비자 중심</span>
              </li>
              <li
                style={{
                  background:
                    "url('../imgs/svg/ccm-value-img02.svg') no-repeat center",
                }}
              >
                <span>투명성</span>
              </li>
              <li
                style={{
                  background:
                    "url('../imgs/svg/ccm-value-img03.svg') no-repeat center",
                }}
              >
                <span>혁신</span>
              </li>
            </HorizontalMoveAnimation>
          </OpacityAnimation>
          <OpacityAnimation
            element="li"
            className="vision"
            delay={0.1}
            duration={1.5}
          >
            <div>
              <div>
                <img src="../imgs/svg/command.svg" alt="커맨드아이콘" />
              </div>
              <HorizontalMoveAnimation
                element="p"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                구매 과정의 디지털화, 정보의 투명화, 합리적인 가격구조
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="span"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                모든 소비자가 신차 구매, 내 차 팔기, 자동차 금융, 자동차
                다이렉트 보험 등 자동차 관련 모든 서비스를 이용 시, 편안함과
                만족감을 주는 세상을 만들겠습니다.
              </HorizontalMoveAnimation>
            </div>
          </OpacityAnimation>
        </ul>
        <div>
          <img src="../imgs/svg/arrow_down_big.svg" alt="큰 아래방향 화살표" />

          <VerticalMoveAnimation
            element="h2"
            delay={0.2}
            duration={0.5}
            direction="top"
            className="m-section-title"
          >
            <span>기업전략</span>
            <HorizontalMoveAnimation
              element="span"
              delay={0.7}
              duration={0.1}
              direction="left"
              style={{ display: "inline-block" }}
            >
              <UnderlineSketch width="120" fill="#FFE492" />
            </HorizontalMoveAnimation>
          </VerticalMoveAnimation>
          <OpacityAnimation
            element="p"
            className="vision"
            delay={0.2}
            duration={1.5}
          >
            <span>투명하고 체계적인 판매 및 매입 시스템으로</span>
            <span>자동차 시장의 깨끗한 유통 거래를 선도</span>
          </OpacityAnimation>
        </div>
      </section>
    );
  };

  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <Section03 />
    </div>
  );
};
export default CarsayoMobileCCM;
