import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Overview04Fourth = () => {
  return (
    <div className="section overview__section04">
      <div className="overview__section04__content">
        <div className="overview__section04__content__wrap">
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            className="title"
          >
            2020
          </VerticalMoveAnimation>
          <div className="box">
            <ul className="history">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                direction="left"
                className="content"
                duration={0.5}
              >
                <div>
                  <span>2020.12</span>
                  <p>카사요 앱 iOS 버전 출시</p>
                </div>
                <div>
                  <img
                    src="../imgs/svg/symbol-previous.svg"
                    alt="구버전IOS심볼"
                  />
                </div>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.3}
                direction="left"
                className="content"
                duration={0.5}
              >
                <div>
                  <span>2020.08</span>
                  <p>카사요(주) 설립</p>
                </div>
                <div style={{ paddingTop: "5vh" }}>
                  <img
                    src="../imgs/svg/logo-previous.svg"
                    style={{ height: "6.25rem" }}
                    alt="구버전로고"
                  />
                </div>
              </HorizontalMoveAnimation>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Overview04Fourth;
