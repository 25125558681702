import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
// import close_icon from "../../../public/imgs/svg/close.svg";

interface CarsayoModalPopupContainerInterface {
  isShown: boolean;
  isFullHeight?: boolean;
  children: React.ReactNode;
  onClose: () => void;
  title: string;
}

const CarsayoFullPopup = ({
  isShown,
  isFullHeight = false,
  children,
  title,
  onClose,
}: CarsayoModalPopupContainerInterface) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const handleClickEvent = {
    modalClose: function (this: HTMLDivElement, event: MouseEvent) {
      event.preventDefault();
      if (isShown) onClose();
    },
  };

  const popupContainerSpringAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        default: {
          duration: 0.3,
          ease: [0, 0.71, 0.2, 1.01],
        },

        // scale: {
        //   type: "spring",
        //   damping: 5,
        //   stiffness: 100,
        //   restDelta: 0.002,
        // },
      },
    },
    exit: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      },
    },
  };

  useEffect(() => {
    if (backgroundRef.current) {
      backgroundRef.current.addEventListener(
        "click",
        handleClickEvent.modalClose
      );
    }
    return () => {
      if (backgroundRef.current) {
        backgroundRef.current.removeEventListener(
          "click",
          handleClickEvent.modalClose
        );
      }
    };
  }, [backgroundRef.current, isShown]);
  return (
    <>
      <AnimatePresence>
        <motion.div
          variants={popupContainerSpringAnimation}
          initial={"hidden"}
          animate={"show"}
          exit={"exit"}
          className="fullPopup"
          style={
            isShown
              ? { visibility: "initial", opacity: "1", zIndex: "300" }
              : { visibility: "hidden", opacity: "0", zIndex: "-999" }
          }
        >
          <motion.div
            variants={popupContainerSpringAnimation}
            initial={"hidden"}
            animate={"show"}
            exit={"exit"}
          >
            {/* 상단 */}
            <div>
              <p>{title}</p>
              <button onClick={onClose}>
                <img src={"../imgs/svg/close.svg"} alt="닫기이미지" />
              </button>
            </div>
            {/* 컨텐츠영역 */}
            <div>{children}</div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default CarsayoFullPopup;
