import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Character05 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section character__section05">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation
            element="h3"
            duration={0.5}
            direction="top"
            style={{ fontSize: "3.5rem" }}
          >
            CHARACTER APPLICATION
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__section05__contents">
          <ul>
            <VerticalMoveAnimation
              element="li"
              duration={0.5}
              direction="top"
              delay={0.1}
            >
              <img
                src="../imgs/png/character_section05_img01.png"
                alt="카사요히어로즈이미지1"
              />
            </VerticalMoveAnimation>
            <VerticalMoveAnimation
              element="li"
              duration={0.5}
              direction="top"
              delay={0.2}
            >
              <img
                src="../imgs/png/character_section05_img02.png"
                alt="카사요히어로즈이미지2"
              />
            </VerticalMoveAnimation>
            <VerticalMoveAnimation
              element="li"
              duration={0.5}
              direction="top"
              delay={0.3}
            >
              <img
                src="../imgs/png/character_section05_img03.png"
                alt="카사요히어로즈이미지3"
              />
            </VerticalMoveAnimation>
          </ul>
          <div className="bgBox"></div>
        </div>
      </div>
    </div>
  );
};
export default Character05;
