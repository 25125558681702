import React from "react";
import { ReactNode } from "react";

interface CarsayoButtonInterface {
  children: ReactNode;
  type?: "default" | "winona";
  onIcon?: string;
  iconSize?: string;
  bgColor?: string;
  padding?: string;
  onClick?: () => void;
}

const CarsayoButton = ({
  children,
  type,
  onIcon,
  iconSize,
  bgColor,
  padding,
  onClick,
}: CarsayoButtonInterface) => {
  return (
    <button
      data-text={type === "winona" && children}
      className={`${
        type ? (type === "default" ? "btn" : "btn--winona") : "btn"
      }`}
      style={{ backgroundColor: bgColor, padding: padding }}
      onClick={onClick}
    >
      <span>{children}</span>
      {type !== "winona" && onIcon && (
        <img
          src={onIcon}
          alt="버튼아이콘"
          style={{ width: iconSize, objectFit: "contain" }}
        />
      )}
    </button>
  );
};
export default CarsayoButton;
