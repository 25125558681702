import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import React, { useState } from "react";

const Overview03 = () => {
  const [activeMenu, setActiveMenu] = useState("all");
  return (
    <div className="section overview__section03">
      <div className="company__section__title">
        <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
          BUSINESS
          <span>사업영역</span>
        </VerticalMoveAnimation>
      </div>
      <div className="overview__section03__content">
        <div className="left">
          <img src="../imgs/gif/carsayo_splash.gif" alt="카사요스플래시" />
        </div>
        <div className="right">
          <div>
            <button
              className={`${activeMenu === "all" ? "active" : ""}`}
              onClick={() => {
                setActiveMenu("all");
              }}
            >
              전체보기
            </button>
            <span
              className={`${activeMenu === "25" ? "active" : ""}`}
              onClick={() => {
                setActiveMenu("25");
              }}
            >
              26년 사업
            </span>
          </div>

          <ul>
            <li className={`${activeMenu !== "25" && "active"}`}>
              <div>
                <img src="../imgs/png/carIcon.png" alt="사업아이콘" />
              </div>
              <span>리스/장기렌트</span>
            </li>
            <li className={`${activeMenu !== "25" && "active"}`}>
              <div>
                <img src="../imgs/png/carIcon.png" alt="사업아이콘" />
              </div>
              <span>신차 구매</span>
            </li>
            <li className={`${activeMenu !== "25" && "active"}`}>
              <div>
                <img src="../imgs/png/crownIcon.png" alt="사업아이콘" />
              </div>
              <span>슈퍼카 판매</span>
            </li>
            <li className={`${activeMenu !== "25" && "active"}`}>
              <div>
                <img src="../imgs/png/receipt-editIcon.png" alt="사업아이콘" />
              </div>
              <span>내 차 팔기</span>
            </li>
            <li className={`${activeMenu !== "25" && "active"}`}>
              <div>
                <img src="../imgs/png/receiptIcon.png" alt="사업아이콘" />
              </div>
              <span>자동차 다이렉트 보험</span>
            </li>
            <li className={`${activeMenu !== "25" && "active"}`}>
              <div>
                <img src="../imgs/png/3d-rotateIcon.png" alt="사업아이콘" />
              </div>
              <span>자동차 수입 / 수출</span>
            </li>
            <li className="active">
              <div>
                <img
                  src="../imgs/png/presention-chartIcon.png"
                  alt="사업아이콘"
                />
              </div>
              <span>AI 가격 추적 시스템</span>
            </li>
            <li className="active">
              <div>
                <img src="../imgs/png/smart-carIcon.png" alt="사업아이콘" />
              </div>
              <span>정비 및 탁송 서비스</span>
            </li>
            <li className="active">
              <div>
                <img src="../imgs/png/drivingIcon.png" alt="사업아이콘" />
              </div>
              <span>단기 렌트 서비스</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Overview03;
