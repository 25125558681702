import { motion, useAnimation } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import Overview02 from "../overview/section02";
import Overview03 from "../overview/section03";
import Overview04 from "../overview/section04";
import Overview04Second from "../overview/section04_2";
import Overview04Third from "../overview/section04_3";
import Overview04Fourth from "../overview/section04_4";
import Overview01 from "../overview/section01";
import CarsayoTopButton from "../../components/CarsayoTopButton";
import CarsayoFooter from "../../layout/footer";
import CarsayoHeader from "../../layout/header";
import OverviewProgressBox from "../overview/progress";
import { debounce } from "lodash";
import Overview04First from "./section04_1";
import CarsayoContentWrapV2 from "../../layout/content_v2";

interface CarsayoOCOverviewInterface {
  isMobile: boolean;
}

const CarsayoPCOverView = ({ isMobile }: CarsayoOCOverviewInterface) => {
  const pageRef = useRef<number>(1);
  const scrollingRef = useRef<boolean>(false);
  const outerDivRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const controls = useAnimation();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const handleClickEvent = {
    move: (page: number) => {
      const pageHeight = window.innerHeight;
      setCurrentPage(page);
      pageRef.current = page;
      scrollingRef.current = true;
      outerDivRef.current?.scrollTo({
        top: pageHeight * (page - 1),
        left: 0,
        behavior: "smooth",
      });
      outerDivRef.current?.addEventListener(
        "scrollend",
        () => {
          scrollingRef.current = false;
        },
        {
          once: true,
        }
      );
    },
  };

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const moveTo = {
    top: () => {
      setCurrentPage(1);
      outerDivRef.current?.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    prev: () => {
      if (!outerDivRef.current) return;

      const pageHeight = window.innerHeight;

      if (pageRef.current > 1) {
        const prevPage = pageRef.current - 1;
        setCurrentPage(prevPage);
        pageRef.current = prevPage;
        scrollingRef.current = true;
        outerDivRef.current.scrollTo({
          top: pageHeight * (prevPage - 1),
          left: 0,
          behavior: "smooth",
        });
        outerDivRef.current.addEventListener(
          "scrollend",
          () => {
            scrollingRef.current = false;
          },
          {
            once: true,
          }
        );
      }
    },
    next: () => {
      if (!outerDivRef.current) return;

      const pageHeight = window.innerHeight;
      const totalHeight = outerDivRef.current?.scrollHeight ?? 0;
      const totalPages = Math.ceil(totalHeight / pageHeight);

      if (pageRef.current < totalPages) {
        const nextPage = pageRef.current + 1;
        setCurrentPage(nextPage);
        pageRef.current = nextPage;
        scrollingRef.current = true;
        outerDivRef.current.scrollTo({
          top: pageHeight * (nextPage - 1),
          left: 0,
          behavior: "smooth",
        });

        outerDivRef.current.addEventListener(
          "scrollend",
          () => {
            scrollingRef.current = false;
          },
          {
            once: true,
          }
        );
      }
    },
  };

  const wheelHandler = useCallback(
    debounce(
      (e: WheelEvent) => {
        e.preventDefault();

        if (!outerDivRef.current) return;

        const { deltaY } = e;

        if (deltaY > 0) {
          moveTo.next();
        } else {
          moveTo.prev();
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    []
  );
  const keydownHandler = useCallback(
    debounce(
      (e: KeyboardEvent) => {
        e.preventDefault();

        if (!outerDivRef.current) return;

        const { key } = e;

        switch (key) {
          case "ArrowUp":
            moveTo.prev();
            break;
          case "ArrowDown":
            moveTo.next();
            break;
          default:
            break;
        }
      },
      300,
      { leading: true, trailing: false }
    ),
    []
  );

  useEffect(() => {
    if (!wheelHandler) return;
    const outerDivRefCurrent = outerDivRef.current;
    if (outerDivRefCurrent) {
      outerDivRefCurrent.addEventListener("wheel", wheelHandler, {
        passive: false,
      });

      document.addEventListener("keydown", keydownHandler, {
        passive: false,
      });
    }
    return () => {
      if (outerDivRefCurrent) {
        outerDivRefCurrent.removeEventListener("wheel", wheelHandler);
        document.removeEventListener("keydown", keydownHandler);
      }
    };
  }, [wheelHandler, keydownHandler]);

  useEffect(() => {
    if (currentPage > 1) {
      controls.start({ y: -150 }); // 헤더 숨기기
    } else {
      controls.start({ y: 0 }); // 헤더 보이기
    }
  }, [currentPage, controls]);
  return (
    <CarsayoContentWrapV2>
      <Overview01
        activeIndex={activeIndex ?? 1}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        moveContent={handleClickEvent.move}
      />
      <Overview02 />
      <Overview03 />
      <Overview04 />
      <Overview04First />
      <Overview04Second />
      <Overview04Third />
      <Overview04Fourth />
      <OverviewProgressBox
        currentPage={currentPage}
        goMove={handleClickEvent.move}
      />
    </CarsayoContentWrapV2>
  );
};
export default CarsayoPCOverView;
