import { motion, useAnimation } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CarsayoHeader from "../layout/header";
import CarsayoFooter from "../layout/footer";
import Character01 from "./character/section01";
import CarsayoContentWrap from "../layout/content";
import React from "react";
import CarsayoMobileWrap from "../layout/mobileWrap";
import OSCheck from "../components/OSCheck";
import CarsayoPCOverView from "./overview/overviewPC";
import CarsayoPCCharacter from "./character/characterPC";
import { useNavigate } from "react-router-dom";
import CarsayoMobileCharacter from "./character/characterMobile";

const CompanyCharacter = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   if (isMobile) {
  //     navigate("/");
  //   }
  // }, [isMobile]);
  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" ? (
        <CarsayoMobileWrap>
          <CarsayoMobileCharacter />
        </CarsayoMobileWrap>
      ) : isMobile ? (
        <CarsayoMobileWrap>
          <CarsayoMobileCharacter />
        </CarsayoMobileWrap>
      ) : (
        // <CarsayoContentWrap isMobile={isMobile}>
        //   <Character01 />
        // </CarsayoContentWrap>
        <CarsayoPCCharacter isMobile={isMobile} />
      )}
    </>
  );
};
export default CompanyCharacter;
