export default function OSCheck():
  | "iOS"
  | "Android"
  | "Mac"
  | "Windows"
  | "etc" {
  const userAgent = navigator.userAgent;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // Mac detection
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return "Mac";
  }

  // Windows detection
  if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
    return "Windows";
  }

  return "etc";
}
