import { motion, MotionStyle } from "framer-motion";
import React from "react";
import { Style } from "util";

interface VerticalMoveAnimationInterface {
  element: "h2" | "h3" | "h4" | "div" | "span" | "p" | "li" | "strong" | "ul";
  children: React.ReactNode;
  direction: "top" | "bottom";
  className?: string;
  style?: MotionStyle;
  setAnimated?: (isAnimated: boolean) => void;
  strong?: string;
  delay?: number;
  duration?: number;
}

const VerticalMoveAnimation = ({
  element,
  children,
  direction,
  className,
  style,
  setAnimated,
  strong,
  delay,
  duration,
}: VerticalMoveAnimationInterface) => {
  const MotionElement = motion[element];
  return (
    <MotionElement
      className={className ?? ""}
      style={style ?? undefined}
      initial={{ opacity: 0, y: direction === "top" ? -20 : 20 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { delay: delay || 0.1, duration: duration || 0.3 },
      }}
      onAnimationStart={setAnimated ? () => setAnimated(false) : () => {}}
      onAnimationComplete={setAnimated ? () => setAnimated(true) : () => {}}
    >
      {strong && <strong>{strong}</strong>}
      {children}
    </MotionElement>
  );
};

export default VerticalMoveAnimation;
