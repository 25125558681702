import { motion, MotionStyle } from "framer-motion";

interface OpacityAnimationInterface {
  element:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "div"
    | "span"
    | "p"
    | "li"
    | "strong"
    | "ul";
  children: React.ReactNode;
  className?: string;
  style?: MotionStyle;
  setAnimated?: (isAnimated: boolean) => void;
  strong?: string;
  delay?: number;
  duration?: number;
}

const OpacityAnimation = ({
  element,
  children,
  className,
  style,
  setAnimated,
  strong,
  delay,
  duration,
}: OpacityAnimationInterface) => {
  const MotionElement = motion[element];
  return (
    <MotionElement
      style={style ?? undefined}
      className={className ?? ""}
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { delay: delay || 0.1, duration: duration || 0.3 },
      }}
      onAnimationStart={setAnimated ? () => setAnimated(false) : () => {}}
      onAnimationComplete={setAnimated ? () => setAnimated(true) : () => {}}
    >
      {strong && <strong>{strong}</strong>}

      {children}
    </MotionElement>
  );
};

export default OpacityAnimation;
