import React from "react";
import CarsayoMobileHeader from "./mobileHeader";
import CarsayoMobileFooter from "./mobileFooter";

interface CarsayoContentWrapInterface {
  children: React.ReactNode;
  hiddenHeader?: boolean;
  hiddenFooter?: boolean;
}

const CarsayoMobileWrap = ({
  children,
  hiddenHeader,
  hiddenFooter,
}: CarsayoContentWrapInterface) => {
  return (
    <div className="m-main">
      {hiddenHeader === true ? null : <CarsayoMobileHeader />}

      {children}
      {hiddenFooter === true ? null : <CarsayoMobileFooter />}
    </div>
  );
};
export default CarsayoMobileWrap;
