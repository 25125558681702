import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

interface TypingTextAnimationInterface {
  text: string;
  element: "h2" | "h3" | "h4" | "div" | "p" | "li" | "span";
  className?: string;
  isAnimated?: boolean;
}

const TypingTextAnimation = ({
  text,
  element,
  className,
  isAnimated,
}: TypingTextAnimationInterface) => {
  const MotionElement = motion[element];
  const controls = useAnimation();
  const letters = text.split("");

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { delay: 0.1, staggerChildren: 0.1, delayChildren: 0.09 * i },
    }),
  };

  const child = {
    hidden: {
      opacity: 0,
      x: -20,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 300,
      },
    },
  };

  useEffect(() => {
    if (isAnimated) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isAnimated]);

  return (
    <MotionElement
      className={`animated-text ${className ?? ""}`}
      variants={container}
      initial="hidden"
      animate={controls}
    >
      {letters.map((letter, index) => (
        <motion.span key={index} variants={child}>
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))}
    </MotionElement>
  );
};

export default TypingTextAnimation;
