import { motion } from "framer-motion";
import { useState } from "react";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import React from "react";

const MainSection04 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section wrap">
      <div className="main-section04">
        <div className="left">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 500,
              ease: "linear",
            }}
          >
            <img
              src="../imgs/svg/workTogether01.svg"
              alt="네트워크이미지바깥쪽"
            />
          </motion.div>
          <motion.div
            animate={{ rotate: 360 }}
            transition={{
              repeat: Infinity,
              repeatType: "loop",
              duration: 100,
              ease: "linear",
            }}
          >
            <img
              src="../imgs/svg/workTogether02.svg"
              alt="네트워크이미지안쪽"
              style={{ width: "65%" }}
            />
          </motion.div>
          <div className="logo">
            <img src="../imgs/svg/logoSmall.svg" alt="작은 로고" />
          </div>
        </div>
        <div className="right">
          <div>
            <VerticalMoveAnimation
              element="div"
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
            >
              <h3>
                <span>국내 최대 네트워크</span>
                <span>인프라 보유 플랫폼</span>
              </h3>
              <span className={isAnimated ? "animation" : ""}>
                <UnderlineSketch fill="#FFE492" />
              </span>
            </VerticalMoveAnimation>
          </div>
          <div>
            <VerticalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
              style={{ display: "inline-block" }}
            >
              국내 최대 카매니저&에이전시 네트워크 인프라를 활용하여 최적의
              선택을 할 수 있도록 돕습니다.
            </VerticalMoveAnimation>
            <VerticalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="top"
              setAnimated={setAnimated}
              style={{ display: "inline-block" }}
            >
              허위 견적 없이 선택한 차량과 옵션에 맞춘 전국의 다양한 견적을
              실시간으로 비교할 수 있습니다.
            </VerticalMoveAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection04;
