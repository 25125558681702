import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import CarsayoCharacterPopup from "../../components/CarsayoCharacterPopup";
import Expression from "../../components/svg/Expression";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character03 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section character__section03">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CHARACTER CONCEPT
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__contents">
          <div className="left">
            <img
              src="../imgs/svg/character-section03-img.svg"
              alt="카집사 이미지"
            />
            <div className="character-nameTag">
              <div>
                <p>항상 웃음을 잃지 않는 감정적이면서 따뜻한 마음을 가진 </p>
                <h5>카집사</h5>
              </div>
            </div>
          </div>
          <div className="right">
            <VerticalMoveAnimation
              element="div"
              className="right__title"
              duration={0.5}
              direction="top"
            >
              <p>#2 HEROES 2025</p>
              <h3>
                <span>카집사</span>
              </h3>
            </VerticalMoveAnimation>
            <div className="right__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>
                  방치되었던 자동차에 가장 먼저 반응을 한 것은 카집사라고 한다.
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  경청을 굉장히 잘하며 카집사의 예의바른 언행과 살가운 태도에
                </span>
                <span>
                  몇번 만나지 않은 사람도 금새 편안해져 상담을 요청하게 된다.
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  항상 존대말을 사용하며 깔끔하게 관리된 머리와 수염을
                  유지하는데
                </span>
                <span>이는 자신을 마주하는 사람들에 대한 예의라고 한다.</span>
              </HorizontalMoveAnimation>
            </div>
            <Expression
              textColor={"#fff"}
              fill="#FF5B79"
              clickEvent={setPopupOpen}
            />
            <div className="character-clip-box">
              <img
                src="../imgs/png/character-clip03.png"
                alt="캐릭터 클립 이미지3"
              />
              <span className="clip-bg">
                <img src="../imgs/png/clip.png" alt="클립이미지" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character03;
