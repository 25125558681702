import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";

const CarsayoMobilePhilosophy = () => {
  // sectioin01 - 경영 이념
  const Section01 = () => {
    return (
      <section
        className="m-philosophy01"
        style={{
          background: "url('../imgs/svg/philosophy-bg.svg') no-repeat center",
        }}
      >
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title"
        >
          경영이념
        </VerticalMoveAnimation>
        <OpacityAnimation element="div" delay={0.3} duration={1.5}>
          <img src="../imgs/svg/philosophy-ci.svg" alt="카사요ci" />
          <span>자동차 쇼핑 통합 플랫폼</span>
          <OpacityAnimation element="strong" delay={0.5} duration={1}>
            ALL IN ONE CARSAYO
          </OpacityAnimation>
        </OpacityAnimation>
      </section>
    );
  };

  // section02 - 미션
  const Section02 = () => {
    return (
      <section className="m-subPage-content">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          미션
        </VerticalMoveAnimation>
        <div>
          <div
            style={{
              background:
                "url(../imgs/svg/philosophy-section02-bg.svg) no-repeat center",
            }}
          >
            <HorizontalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="left"
              className={"normal-box"}
            >
              <p>고객의 경험을 풍요롭게</p>
              <span>NEVER-ENDING GROWTH</span>
            </HorizontalMoveAnimation>
          </div>
          <OpacityAnimation element="ul" delay={0.1} duration={1.5}>
            <li>
              <p>
                <span>안전하고 편리한 자동차 생활 서비스를 제공하여</span>
                <span>고객이 합리적으로 선택할 수 있는</span>
                <span>국민 기업으로 성장하고 있습니다.</span>
              </p>
            </li>
          </OpacityAnimation>
        </div>
      </section>
    );
  };

  // section03 - 비전
  const Section03 = () => {
    return (
      <section className="m-subPage-content">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          비전
        </VerticalMoveAnimation>
        <div>
          <div
            style={{
              background:
                "url(../imgs/svg/philosophy-section03-bg.svg) no-repeat center",
            }}
          >
            <HorizontalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="left"
              className={"normal-box"}
            >
              <p>지속 발전 가능한 바른 경영</p>
              <span>NEVER-ENDING GROWTH</span>
            </HorizontalMoveAnimation>
          </div>
          <OpacityAnimation element="ul" delay={0.1} duration={1.5}>
            <li>
              <p>
                <span>혁신적인 기술과 솔루션을 제공하는</span>
                <span>자동차 업계 리더로서 바른 경영을 통해</span>
                <span>지속 가능한 미래를 만들겠습니다.</span>
              </p>
            </li>
          </OpacityAnimation>
        </div>
      </section>
    );
  };

  // section04 - 핵심
  const Section04 = () => {
    return (
      <section className="m-philosophy04">
        <HorizontalMoveAnimation
          element="h2"
          delay={0.3}
          duration={0.5}
          direction="left"
        >
          <span>CORE</span>
          <HorizontalMoveAnimation
            element="span"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            VALUE
          </HorizontalMoveAnimation>
        </HorizontalMoveAnimation>
        <VerticalMoveAnimation
          element="ul"
          delay={0.2}
          duration={0.5}
          direction="top"
        >
          <VerticalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="top"
          >
            <img
              src="../imgs/png/philosophy-valueBg01.png"
              alt="핵심이미지01"
            />
            <div>
              <span>01</span>
              <p>창의적인 변화와 도전</p>
            </div>
          </VerticalMoveAnimation>
          <VerticalMoveAnimation
            element="li"
            delay={0.3}
            duration={0.5}
            direction="top"
          >
            <img
              src="../imgs/png/philosophy-valueBg02.png"
              alt="핵심이미지02"
            />
            <div>
              <span>02</span>
              <p>신뢰하는 소통과 협력</p>
            </div>
          </VerticalMoveAnimation>
          <VerticalMoveAnimation
            element="li"
            delay={0.4}
            duration={0.5}
            direction="top"
          >
            <img
              src="../imgs/png/philosophy-valueBg03.png"
              alt="핵심이미지03"
            />
            <div>
              <span>03</span>
              <p>지속적인 발전과 상생</p>
            </div>
          </VerticalMoveAnimation>
        </VerticalMoveAnimation>
      </section>
    );
  };

  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <div
        style={{ width: "100%", height: "12px", backgroundColor: "#F1F1F5" }}
      ></div>
      <Section03 />
      <Section04 />
    </div>
  );
};
export default CarsayoMobilePhilosophy;
