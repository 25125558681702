import { motion } from "framer-motion";

interface OverviewProgress {
  currentPage: number;
  goMove: (page: number) => void;
}

const OverviewProgressBox = ({ currentPage, goMove }: OverviewProgress) => {
  return (
    <div
      className="overview__section04__content__progress"
      style={{
        opacity: `${currentPage > 5 && currentPage < 9 ? "1" : "0"}`,
        transition: "opacity .3s ease",
      }}
    >
      <div className="page-button">
        <span
          className="page-button__prev"
          onClick={
            currentPage - 4 < 4
              ? () => {
                  goMove(currentPage + 1);
                }
              : () => {}
          }
          style={{ cursor: `${currentPage < 8 ? "pointer" : "default"}` }}
        >
          <img src="../imgs/svg/smallArrow_left.svg" alt="작은 우측 화살표" />
        </span>
        <span className="page-button__current">
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
              transition: { delay: 0.1, duration: 0.3 },
            }}
            style={{ color: "#111", fontWeight: "600" }}
          >
            {currentPage < 9 && currentPage > 4 ? currentPage - 4 : 4}
          </motion.span>
          /4
        </span>
        <span
          className="page-button__next"
          onClick={
            currentPage - 4 > 1
              ? () => {
                  goMove(currentPage - 1);
                }
              : () => {}
          }
          style={{ cursor: `${currentPage > 5 ? "pointer" : "default"}` }}
        >
          <img src="../imgs/svg/smallArrow_right.svg" alt="작은 좌측 화살표" />
        </span>
      </div>
      <span
        className="progress-bar"
        style={{ width: `calc(25% * ${currentPage - 4})` }}
      ></span>
      <span>자동차 시장을 선도하는</span>
    </div>
  );
};
export default OverviewProgressBox;
