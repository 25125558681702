import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import Expression from "../../components/svg/Expression";
import CarsayoCharacterPopup from "../../components/CarsayoCharacterPopup";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character04 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section character__section04">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CHARACTER CONCEPT
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__contents">
          <div className="left">
            <VerticalMoveAnimation
              element="div"
              className="left__title"
              duration={0.5}
              direction="top"
            >
              <p>#3 HEROES 2025</p>
              <h3>
                <span>카공이 </span>
              </h3>
            </VerticalMoveAnimation>
            <div className="left__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>
                  차와 대화가 가능해 문제가 무엇인지 금방 파악할 수 있다고 한다.
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  차량 관리에 부담을 느끼는 고객들의 부담감을 덜어주는 듯
                  듬직하고
                </span>
                <span>
                  큰 덩치를 가지고 있으며 시원하고 털털한 성격으로 수다 떠는
                  것을 좋아하는데,
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  카공이 앞에서 섣불리 엔진 등에 대한 이야기를 꺼냈다가는
                </span>
                <span>날밤을 샐 수도 있다고 하니 조심!</span>
              </HorizontalMoveAnimation>
            </div>
            <Expression
              textColor="#303056"
              fill="#A7DE93"
              clickEvent={setPopupOpen}
            />
            <div className="character-clip-box">
              <img
                src="../imgs/png/character-clip04.png"
                alt="캐릭터 클립 이미지4"
              />
              <span className="clip-bg">
                <img src="../imgs/png/clip.png" alt="클립이미지" />
              </span>
            </div>
          </div>
          <div className="right">
            <img
              src="../imgs/svg/character-section04-img.svg"
              alt="카공이  이미지"
            />
            <div className="character-nameTag">
              <div>
                <p>차량의 모든 부분을 세밀하게 알고있는</p>
                <h5>카공이</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character04;
