import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../utils/opacityAnimation";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";

const CarsayoMobileESG = () => {
  // sectioin01 -  ESG 경영
  const Section01 = () => {
    return (
      <section
        className="m-esg01"
        style={{
          background: "url('../imgs/svg/esg-m-bg.svg') no-repeat top",
        }}
      >
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title"
        >
          ESG경영
        </VerticalMoveAnimation>
        <OpacityAnimation element="div" delay={0.1} duration={1.5}>
          <p>
            {/* <span>카사요는</span>
            <span>자동차 플랫폼 중 국내 최초</span>
            <span>ESG 인증 취득 기업입니다</span> */}
            <span>카사요</span>
            <span>자동차 쇼핑 통합 플랫폼</span>
          </p>
        </OpacityAnimation>
        <HorizontalMoveAnimation
          element="p"
          delay={0.3}
          duration={0.5}
          direction="left"
        >
          <span>우리 회사는</span>
          <span>지속 가능한 미래를 위해 ESG 경영을</span>
          <span>적극 실천하고 있으며, 이를 통해 환경, 사회,</span>
          <span>그리고 지배구조 면에서 모범적인 기업이 되고자</span>
          <span>노력하고 있습니다.</span>
        </HorizontalMoveAnimation>
      </section>
    );
  };
  // section02 - ESG 경영 전략
  const Section02 = () => {
    return (
      <section className="m-esg02">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
          style={{ letterSpacing: "11.2px", marginLeft: "2.8px" }}
        >
          {/* <h2
          className="subPage-title02"
          style={{ letterSpacing: "11.2px", marginLeft: "2.8px" }}
        > */}
          카사요ESG경영전략
        </VerticalMoveAnimation>
        <ul>
          <OpacityAnimation element="li" delay={0.1} duration={1.5}>
            <div
              style={{
                background: "url(../imgs/png/esg-li01.png) no-repeat center",
              }}
            >
              <h3>
                <span>환경안전 경영</span>
              </h3>
            </div>
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
            >
              <strong>환경안전 경영 지속 가능한 경영</strong>
              <OpacityAnimation element="p" delay={0.1} duration={1.5}>
                <span>
                  을 위해 환경경영시스템(ISO14001)을 취득하였으며 환경 및 안전
                  법규를 준수하여 사고 예방 활동에 전 구성원이 적극 참여하고
                  있습니다.
                </span>
              </OpacityAnimation>
            </HorizontalMoveAnimation>
          </OpacityAnimation>
          <OpacityAnimation element="li" delay={0.1} duration={1.5}>
            <div
              style={{
                background: "url(../imgs/png/esg-li02.png) no-repeat center",
              }}
            >
              <h3>사회책임 경영</h3>
            </div>
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
            >
              <strong>우리는 다양한 사회공헌 활동</strong>
              <OpacityAnimation element="p" delay={0.1} duration={1.5}>
                <span>
                  을 통해 지역사회와의 상생을 도모하며 공정한 거래와 윤리적
                  경영을 통해 사회적 책임을 다하고 있습니다.
                </span>
              </OpacityAnimation>
            </HorizontalMoveAnimation>
          </OpacityAnimation>
          <OpacityAnimation element="li" delay={0.1} duration={1.5}>
            <div
              style={{
                background: "url(../imgs/png/esg-li03.png) no-repeat center",
              }}
            >
              <h3>지배구조 경영</h3>
            </div>
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
            >
              <strong>공정하고 투명한 경영</strong>
              <OpacityAnimation element="p" delay={0.1} duration={1.5}>
                <span>을 위해 지속적으로 노력하고 있습니다.</span>
                <span>
                  신뢰를 바탕으로 한 투명한 의사결정을 통해 지속 가능한 성장을
                  추구합니다.
                </span>
              </OpacityAnimation>
            </HorizontalMoveAnimation>
          </OpacityAnimation>
        </ul>
      </section>
    );
  };
  // section03 - ESG는 무엇인가요?
  const Section03 = () => {
    return (
      <section className="m-esg03">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
          style={{ letterSpacing: "11.2px", marginLeft: "2.8px" }}
        >
          ESG는무엇인가요?
        </VerticalMoveAnimation>
        <VerticalMoveAnimation
          element="p"
          delay={0.3}
          duration={0.5}
          direction="top"
        >
          <span>ESG는</span>
          <span>환경(Environmental),</span>
          <span>사회(Social),</span>
          <span>지배구조(Governance)의</span>
          <span>
            영문 첫 글자를 조합단 단어로 기업 경영에서 지속가능성을 달성하기
            위한 3가지 핵심요소입니다.
          </span>
        </VerticalMoveAnimation>
        <ul>
          <OpacityAnimation
            style={{
              background:
                "url('../imgs/svg/philosophy-bg.svg') no-repeat center",
            }}
            element="li"
            delay={0.1}
            duration={1.5}
          >
            <div>
              <HorizontalMoveAnimation
                element="h3"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <strong>E</strong>
                <span>nvironmental</span>
              </HorizontalMoveAnimation>
              <img
                src="../imgs/svg/esg-li-img01.svg"
                alt="ESG경영리스트 환경이미지"
              />
            </div>
            <ul>
              <li>기후 변화 및 탄소 배출</li>
              <li>환경 오염 및 환경규제</li>
              <li>생태계 및 생물 다양성</li>
            </ul>
          </OpacityAnimation>
          <OpacityAnimation
            style={{
              background: "url('../imgs/svg/ccm-bg.svg') no-repeat center",
            }}
            element="li"
            delay={0.2}
            duration={1.5}
          >
            <div>
              <HorizontalMoveAnimation
                element="h3"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <strong>S</strong>
                <span>ocial</span>
              </HorizontalMoveAnimation>
              <img
                src="../imgs/svg/esg-li-img02.svg"
                alt="ESG경영리스트 사회이미지"
              />
            </div>

            <ul>
              <li>데이터 보호 및 프라이버시</li>
              <li>인권, 성별 평등 및 다양성</li>
              <li>지역사회 관계</li>
            </ul>
          </OpacityAnimation>
          <OpacityAnimation
            style={{
              background: "url('../imgs/svg/esg-li-bg03.svg') no-repeat center",
            }}
            element="li"
            delay={0.3}
            duration={1.5}
          >
            <div>
              <HorizontalMoveAnimation
                element="h3"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <strong>G</strong>
                <span>overnance</span>
              </HorizontalMoveAnimation>
              <img
                src="../imgs/svg/esg-li-img03.svg"
                alt="ESG경영리스트 지배구조이미지"
              />
            </div>
            <ul>
              <li>이사회 및 감사 위원회 구성</li>
              <li>뇌물 및 반 부패</li>
              <li>기업윤리</li>
            </ul>
          </OpacityAnimation>
        </ul>
      </section>
    );
  };

  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <div
        style={{ width: "100%", height: "12px", backgroundColor: "#F1F1F5" }}
      ></div>
      <Section03 />
    </div>
  );
};
export default CarsayoMobileESG;
