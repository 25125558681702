import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Overview04First = () => {
  return (
    <div className="section overview__section04">
      <div
        className="overview__section04__content"
        style={{ justifyContent: "center", gap: "10vh" }}
      >
        <VerticalMoveAnimation
          element="div"
          duration={0.5}
          direction="top"
          className="title"
        >
          2024
        </VerticalMoveAnimation>
        <div className="box">
          <ul className="history">
            <HorizontalMoveAnimation
              element="li"
              delay={0.2}
              direction="left"
              duration={0.5}
            >
              <ul className="history__detail">
                <li className="content">
                  <span>2024.05</span>
                  <p>
                    <span>벤처 기업 인증(혁신 성장형)</span>
                    <span className="small">중소벤처기업부 벤처인증 획득</span>
                  </p>
                  <img
                    src="../imgs/png/ventureImg.png"
                    alt="벤처기업 인증 이미지"
                  />
                </li>
                <li className="content">
                  <span>2024.05</span>
                  <p>
                    <span>기업 부설 연구소 설립</span>
                    <span className="small">
                      과학기술정보통신부 인정서 획득
                    </span>
                  </p>
                  <img
                    src="../imgs/png/laboratoryImg.png"
                    alt="기업 부설 연구소 설립 이미지"
                  />
                </li>
              </ul>
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="li"
              delay={0.4}
              duration={0.5}
              direction="left"
            >
              <ul className="history__detail">
                <li className="content">
                  <span>2024.01</span>
                  <p>
                    <span>ISO 9001(품질경영시스템)</span>
                    <span className="small">인증 획득</span>
                  </p>
                  <img
                    src="../imgs/png/certificateImg01.png"
                    alt="품질경영국제표준인정이미지"
                  />
                </li>
                <li className="content">
                  <span>2024.01</span>
                  <p>
                    <span>ISO 14001(환경경영시스템)</span>
                    <span className="small">인증 획득</span>
                  </p>
                  <img
                    src="../imgs/png/certificateImg02.png"
                    alt="환경경영국제표준인정이미지"
                  />
                </li>
              </ul>
            </HorizontalMoveAnimation>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Overview04First;
