import { useNavigate } from "react-router-dom";
import BackIcon from "../components/svg/BackIcon";
import OpacityAnimation from "../../utils/opacityAnimation";
import HorizontalMoveAnimation from "../../utils/horizontalMoveAnimation";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import SwiperClass from "swiper/types/swiper-class";
import "swiper/swiper-bundle.css";
import Expression from "../components/svg/Expression";

interface SwiperDataArrayInterface {
  text: string;
  textColor: string;
  clickEvent: () => void;
  fill: string;
  width?: string;
  height?: string;
}

const PremiumEventMobile = () => {
  const navigate = useNavigate();

  const swiperDataArray: SwiperDataArrayInterface[] = [
    {
      text: "#1. 자동차 다이렉트 보험",
      textColor: "#303056",
      clickEvent: () => {
        window.location.href = "https://app.carsayo.net/static/insurance";
      },
      fill: "#A7DE93",
      width: "200",
      height: "83",
    },
  ];

  return (
    <div className="premium-unavailable">
      <div className="lamp">
        <img
          src="../imgs/svg/premium-unavailable-lampImg.svg"
          alt="램프이미지"
        />
        <img src="../imgs/svg/premium-unavailable-lightImg.svg" alt="불빛" />
      </div>
      <div className="premium-unavailable__top">
        <button onClick={() => navigate(-1)}>
          <BackIcon />
        </button>
        <OpacityAnimation element="h1" delay={0.1}>
          프리미엄 혜택
        </OpacityAnimation>
      </div>
      <div className="premium-unavailable__box">
        <div className="premium-unavailable__box__content">
          <HorizontalMoveAnimation
            direction="left"
            element="h2"
            delay={0.1}
            duration={0.5}
          >
            <span>Premium</span>
            <span>Service</span>
          </HorizontalMoveAnimation>
          <OpacityAnimation element="div" delay={0.2} duration={1}>
            <img
              src="../imgs/svg/character_section05_img01.svg"
              alt="카사요 히어로즈 이미지"
            />
            <img src="../imgs/svg/light-shadow.svg" alt="조명 그림자 이미지" />
          </OpacityAnimation>
          <VerticalMoveAnimation element="div" delay={0.2} direction="top">
            <h3>With Car Heroes</h3>
          </VerticalMoveAnimation>
        </div>
        {/* 하단 버튼 스와이퍼 */}
        <div className="m-promotion__swiper">
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            centeredSlides={true}
            loop={swiperDataArray.length > 2 ? true : false}
            pagination={{ clickable: true }}
            spaceBetween={16}
          >
            {swiperDataArray &&
              swiperDataArray.length > 0 &&
              swiperDataArray.map((item, index) => {
                return (
                  <SwiperSlide>
                    <Expression
                      className="mb"
                      key={`promotion_swiper_${item.text}_${index}`}
                      text={item.text}
                      textColor={item.textColor}
                      clickEvent={item.clickEvent}
                      fill={item.fill}
                      width={item.width}
                      height={item.height}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default PremiumEventMobile;
