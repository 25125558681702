import Introduction01 from "./introduction/section01";
import Introduction02 from "./introduction/section02";
import Introduction03 from "./introduction/section03";
import Introduction02Second from "./introduction/section02_2";
import { useLayoutEffect, useState } from "react";
import CarsayoMobileWrap from "../layout/mobileWrap";
import CarsayoMobileIntroduction from "./introduction/introductionMobile";
import OSCheck from "../components/OSCheck";
import CarsayoContentWrapV2 from "../layout/content_v2";

const CompanyIntroduction = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" ? (
        <CarsayoMobileWrap>
          <CarsayoMobileIntroduction />
        </CarsayoMobileWrap>
      ) : isMobile ? (
        <CarsayoMobileWrap>
          <CarsayoMobileIntroduction />
        </CarsayoMobileWrap>
      ) : (
        <CarsayoContentWrapV2>
          <Introduction01 />
          <Introduction02 />
          <Introduction02Second />
          <Introduction03 />
        </CarsayoContentWrapV2>
      )}
    </>
  );
};
export default CompanyIntroduction;
