import { useState } from "react";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { motion } from "framer-motion";
import TypingAnimationText from "../components/TypingAnimationText";
import React from "react";

const MainSection06 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section wrap">
      <div className="main-section-left">
        <div className="left">
          <motion.div
            initial={{ opacity: 0, x: "-20%" }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.2, duration: 0.5 },
            }}
          >
            <img src="../imgs/png/mockup2.png" alt="" />
          </motion.div>
        </div>
        <div className="right">
          <div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              onAnimationStart={() => setAnimated(false)}
              onAnimationComplete={() => setAnimated(true)}
            >
              <h3>
                <span>내 차 시세부터 판매까지</span>
                <span>감가 없는 내 차 팔기</span>
              </h3>
              <span
                className={isAnimated ? "animation" : ""}
                style={{ right: "10%" }}
              >
                <UnderlineSketch fill="#FFE492" />
              </span>
            </motion.div>
          </div>
          <div>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.3, duration: 0.3 },
              }}
              style={{ display: "inline-block" }}
            >
              <span>
                정확하고 투명한 정보를 바탕으로 믿을 수 있는 내 차 팔기 서비스를
                제공합니다.
              </span>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { delay: 0.4, duration: 0.3 },
              }}
              style={{ display: "inline-block" }}
            >
              <span>
                국토교통부 인가 단체인 자동차매매사업조합연합회 소속 카
                매니저님들의 견적을 받아볼 수 있습니다.
              </span>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection06;
