/* eslint-disable import/no-anonymous-default-export */

export default () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Check for mobile device identifiers
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return true;
  }
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  if (/BB10|BlackBerry/i.test(userAgent)) {
    return true;
  }
  if (/webOS|Mobile|Opera Mini|IEMobile|iPod|Iphone|ipad/i.test(userAgent)) {
    return true;
  }

  return false;
};
