interface BackIconInterface {
  width?: string;
  height?: string;
  fill?: string;
}

const BackIcon = ({ width, height, fill }: BackIconInterface) => {
  return (
    <svg
      width={width || "28"}
      height={height || "28"}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6665 14H23.3332"
        stroke={fill || "white"}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.6665 7L4.6665 14L11.6665 21"
        stroke={fill || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default BackIcon;
