import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Expression from "../../components/svg/Expression";
import BackIcon from "../../components/svg/BackIcon";
import { CharacterDataArray, CharacterSubDataInterface } from "./data";
import { useNavigate, useParams } from "react-router-dom";

const CharacterMobileSubPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentCharacter, setCurrentCharacter] =
    useState<CharacterSubDataInterface | null>(null);
  const [isShown, setIsShown] = useState("");
  const pageRef = useRef<HTMLDivElement>(null);

  // const 카키: CharacterSubDataInterface = {
  //   titleColor: "#303056",
  //   description: "분석적이며 깐깐한 성격으로 계산 능력까지 뛰어난",
  //   bg: "linear-gradient(245deg,rgba(102, 126, 234, 1) 0%,rgba(118, 75, 162, 1) 100%)",
  //   nextText: "카집사 보기",
  //   nextColor: "#fff",
  //   nextBg: "#6365D9",
  //   nextAction: () => setCurrentKey("카집사"),
  //   imgArray: [
  //     "../imgs/svg/character01_01.svg",
  //     "../imgs/svg/character01_02.svg",
  //     "../imgs/svg/character01_03.svg",
  //     "../imgs/svg/character01_04.svg",
  //     "../imgs/svg/character01_05.svg",
  //     "../imgs/svg/character01_06.svg",
  //     "../imgs/svg/character01_07.svg",
  //     "../imgs/svg/character01_08.svg",
  //   ],
  // };
  // const 카집사: CharacterSubDataInterface = {
  //   titleColor: "#FF5B79",
  //   description: "항상 웃음을 잃지 않는 감성적이면서 따뜻한 마음",
  //   bg: "linear-gradient(180deg,rgba(46, 9, 22, 0.97) 0%,rgba(32, 10, 43, 0.69) 100%)",
  //   nextText: "카공이 보기",
  //   nextColor: "#fff",
  //   nextBg: "#FF5B79",
  //   nextAction: () => setCurrentKey("카공이"),
  //   imgArray: [
  //     "../imgs/svg/character02_01.svg",
  //     "../imgs/svg/character02_02.svg",
  //     "../imgs/svg/character02_03.svg",
  //     "../imgs/svg/character02_04.svg",
  //     "../imgs/svg/character02_05.svg",
  //     "../imgs/svg/character02_06.svg",
  //     "../imgs/svg/character02_07.svg",
  //     "../imgs/svg/character02_08.svg",
  //   ],
  // };
  // const 카공이: CharacterSubDataInterface = {
  //   titleColor: "#fff",
  //   description: "차량의 모든 부분을 세밀하게 알고 있는",
  //   bg: "linear-gradient(180deg,rgba(91, 135, 75, 0.97) 0%,rgba(12, 55, 32, 0.87) 100%)",
  //   nextText: "카키 보기",
  //   nextColor: "#303056",
  //   nextBg: "#A7DE93",
  //   nextAction: () => setCurrentKey("카키"),
  //   imgArray: [
  //     "../imgs/svg/character03_01.svg",
  //     "../imgs/svg/character03_02.svg",
  //     "../imgs/svg/character03_03.svg",
  //     "../imgs/svg/character03_04.svg",
  //     "../imgs/svg/character03_05.svg",
  //     "../imgs/svg/character03_06.svg",
  //     "../imgs/svg/character03_07.svg",
  //     "../imgs/svg/character03_08.svg",
  //   ],
  // };

  // useEffect(() => {
  //   setCurrentKey(keyId);
  // }, [keyId]);
  // useEffect(() => {
  //   if (popupRef.current) {
  //     popupRef.current.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // }, [currentKey]);
  // return currentKey ? (
  //   currentKey === "카키" ? (
  //     <div
  //       ref={popupRef}
  //       className={`m-character-sub ${isShown ? "active" : "hidden"}`}
  //       style={{
  //         backgroundBlendMode: "multiply",
  //         background: 카키.bg,
  //       }}
  //     >
  //       <div className="m-character-sub__top">
  //         <button onClick={setClose}>
  //           <BackIcon fill={카키.nextColor} />
  //         </button>
  //         <h3 style={{ color: 카키.titleColor }}>카키</h3>
  //       </div>
  //       <div className="m-character-sub__contents">
  //         <div className="m-character-sub__contents__box">
  //           <div className="m-character-sub__contents__box__title">
  //             <span></span>
  //             <p>{카키.description}</p>
  //           </div>
  //           <div className="m-character-sub__contents__box__content">
  //             <ul>
  //               {카키.imgArray.length > 0 &&
  //                 카키.imgArray.map((item, index) => {
  //                   return (
  //                     <li>
  //                       <img src={item} alt={`카키 상세 이미지 ${index + 1}`} />
  //                     </li>
  //                   );
  //                 })}
  //             </ul>
  //             <Expression
  //               className="mb"
  //               textColor={카키.nextColor}
  //               fill={카키.nextBg}
  //               clickEvent={카키.nextAction}
  //               text={카키.nextText}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ) : currentKey === "카집사" ? (
  //     <div
  //       ref={popupRef}
  //       className={`m-character-sub ${isShown ? "active" : "hidden"}`}
  //       style={{
  //         backgroundBlendMode: "multiply",
  //         background: 카집사.bg,
  //       }}
  //     >
  //       <div className="m-character-sub__top">
  //         <button onClick={setClose}>
  //           <BackIcon fill={카집사.nextColor} />
  //         </button>
  //         <h3 style={{ color: 카집사.titleColor }}>카집사</h3>
  //       </div>
  //       <div className="m-character-sub__contents">
  //         <div className="m-character-sub__contents__box">
  //           <div className="m-character-sub__contents__box__title">
  //             <span></span>
  //             <p>{카집사.description}</p>
  //           </div>
  //           <div className="m-character-sub__contents__box__content">
  //             <ul>
  //               {카집사.imgArray.length > 0 &&
  //                 카집사.imgArray.map((item, index) => {
  //                   return (
  //                     <li>
  //                       <img
  //                         src={item}
  //                         alt={`카집사 상세 이미지 ${index + 1}`}
  //                       />
  //                     </li>
  //                   );
  //                 })}
  //             </ul>
  //             <Expression
  //               className="mb"
  //               textColor={카집사.nextColor}
  //               fill={카집사.nextBg}
  //               clickEvent={카집사.nextAction}
  //               text={카집사.nextText}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ) : (
  //     <div
  //       ref={popupRef}
  //       className={`m-character-sub ${isShown ? "active" : "hidden"}`}
  //       style={{
  //         backgroundBlendMode: "multiply",
  //         background: 카공이.bg,
  //       }}
  //     >
  //       <div className="m-character-sub__top">
  //         <button onClick={setClose}>
  //           <BackIcon fill={카공이.nextColor} />
  //         </button>
  //         <h3 style={{ color: 카공이.titleColor }}>카공이</h3>
  //       </div>
  //       <div className="m-character-sub__contents">
  //         <div className="m-character-sub__contents__box">
  //           <div className="m-character-sub__contents__box__title">
  //             <span></span>
  //             <p>{카공이.description}</p>
  //           </div>
  //           <div className="m-character-sub__contents__box__content">
  //             <ul>
  //               {카공이.imgArray.length > 0 &&
  //                 카공이.imgArray.map((item, index) => {
  //                   return (
  //                     <li>
  //                       <img
  //                         src={item}
  //                         alt={`카공이 상세 이미지 ${index + 1}`}
  //                       />
  //                     </li>
  //                   );
  //                 })}
  //             </ul>
  //             <Expression
  //               className="mb"
  //               textColor={카공이.nextColor}
  //               fill={카공이.nextBg}
  //               clickEvent={카공이.nextAction}
  //               text={카공이.nextText}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // ) : null;

  const handleClickEvents = {
    goNext: () => {
      setIsShown("hidden");
      if (id) {
        if (id === "01") {
          navigate(`/company/character/detail/02`);
        } else if (id === "02") {
          navigate(`/company/character/detail/03`);
        } else {
          navigate(`/company/character/detail/01`);
        }
      } else {
        navigate(`/company/character/detail/01`);
      }
    },
  };
  useLayoutEffect(() => {
    const goBackHome = () => {
      navigate("/company/character");
    };
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", goBackHome);
    return () => window.removeEventListener("popstate", goBackHome);
  }, [navigate]);

  useEffect(() => {
    console.info(pageRef.current, id);
    if (pageRef.current) {
      pageRef.current.scrollTo({
        top: 0,
      });
    }
    const character = CharacterDataArray.find((char) => char.id === id) ?? null;
    setCurrentCharacter(character);
    setIsShown("active");

    return () => {
      setIsShown("hidden");
    };
  }, [id]);
  return (
    <div className="w-full h-ull bg-white">
      {currentCharacter && (
        <div
          className={`m-character-sub ${isShown}`}
          style={{
            backgroundBlendMode: "multiply",
            background: currentCharacter.bg,
          }}
        >
          <div className="m-character-sub__top">
            <button
              onClick={() => {
                navigate("/company/character");
              }}
            >
              <BackIcon fill={currentCharacter.nextColor} />
            </button>
            <h3 style={{ color: currentCharacter.titleColor }}>
              {currentCharacter.title}
            </h3>
          </div>
          <div className="m-character-sub__contents" ref={pageRef}>
            <div className="m-character-sub__contents__box">
              <div className="m-character-sub__contents__box__title">
                <span></span>
                <p>{currentCharacter.description}</p>
              </div>
              <div className="m-character-sub__contents__box__content">
                <ul>
                  {currentCharacter.imgArray.length > 0 &&
                    currentCharacter.imgArray.map((item, index) => {
                      return (
                        <li>
                          <img
                            src={item}
                            alt={`${currentCharacter.title} 상세 이미지 ${
                              index + 1
                            }`}
                          />
                        </li>
                      );
                    })}
                </ul>
                <Expression
                  className="mb"
                  textColor={currentCharacter.nextColor}
                  fill={currentCharacter.nextBg}
                  clickEvent={handleClickEvents.goNext}
                  text={currentCharacter.nextText}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CharacterMobileSubPage;
