import CarsayoVisualSection from "./main/mainVisual";
import MainSection02 from "./main/mainSection02";
import MainSection03 from "./main/mainSection03";
import MainSection04 from "./main/mainSection04";
import MainSection05 from "./main/mainSection05";
import MainSection06 from "./main/mainSection06";
import MainSection07 from "./main/mainSection07";
import MainSection08 from "./main/mainSection08";
import MainSection09 from "./main/mainSection09";
import MainSection10 from "./main/mainSection10";
import { useLayoutEffect, useState } from "react";
import OSCheck from "./components/OSCheck";
import CarsayoMobileWrap from "./layout/mobileWrap";
import CarsayoMobileMain from "./main/mainMobile";
import CarsayoContentWrapV2 from "./layout/content_v2";
import CarsayoFooter from "./layout/footer";

const CarsayoMain = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" || isMobile ? (
        <CarsayoMobileWrap>
          <CarsayoMobileMain />
        </CarsayoMobileWrap>
      ) : (
        <CarsayoContentWrapV2>
          <CarsayoVisualSection />
          <MainSection02 currentOS={currentOS} />
          <MainSection03 currentOS={currentOS} />
          <MainSection04 />
          <MainSection05 currentOS={currentOS} />
          <MainSection06 />
          <MainSection07 />
          <MainSection08 />
          <MainSection09 currentOS={currentOS} />
          <MainSection10 />
        </CarsayoContentWrapV2>
      )}
    </>
  );
};
export default CarsayoMain;
