interface StarInterface {
  width?: string;
  height?: string;
  fill?: string;
}

const Star = ({ width, height, fill }: StarInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0.5L9.18386 5.68237H14.6329L10.2245 8.88525L11.9084 14.0676L7.5 10.8647L3.09161 14.0676L4.77547 8.88525L0.367076 5.68237H5.81614L7.5 0.5Z"
        fill={fill}
      />
    </svg>
  );
};
export default Star;
