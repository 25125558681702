import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Overview04Second = () => {
  return (
    <div className="section overview__section04">
      <div className="overview__section04__content">
        <div className="overview__section04__content__wrap">
          <VerticalMoveAnimation
            element="div"
            duration={0.5}
            direction="top"
            className="title"
          >
            2023
          </VerticalMoveAnimation>
          <div className="box">
            <ul className="history">
              <HorizontalMoveAnimation
                element="li"
                delay={0.2}
                direction="left"
                className="content"
                duration={0.5}
              >
                <div>
                  <span>2023.12</span>
                  <p>대출성 금융 상품판매대리 중개업 등록</p>
                </div>
                <div>
                  <img
                    src="../imgs/svg/history-2023-img01.svg"
                    alt="대출성 금융 상품판매대리 중개업 등록 이미지"
                  />
                </div>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="li"
                delay={0.4}
                direction="left"
                className="content"
                duration={0.5}
              >
                <div>
                  <span>2023.11</span>
                  <p>연구 개발 전담 부서 설립</p>
                </div>
                <div>
                  <img
                    src="../imgs/svg/history-2023-img02.svg"
                    alt="연구 개발 전담 부서 설립 이미지"
                  />
                </div>
              </HorizontalMoveAnimation>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Overview04Second;
