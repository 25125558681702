import { motion } from "framer-motion";
import { useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import React from "react";

const Logo03 = () => {
  return (
    <div className="section logo__section03">
      <div style={{ overflowY: "hidden", height: "100%" }}>
        <div className="company__section__title">
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            LOGOMARK
            <span>CI 소개</span>
          </VerticalMoveAnimation>
        </div>
        <div className="logo__section03__content">
          <HorizontalMoveAnimation
            element="h4"
            delay={0.2}
            duration={0.3}
            direction="left"
          >
            시그니처 로고
          </HorizontalMoveAnimation>

          <ul>
            <li className="start">
              <img src="../imgs/gif/carsayo_splash.gif" alt="카사요스플래시" />
            </li>
            <li className="between">
              <p>
                <span>부드러운 곡선 구조의 심플한 이니셜 구성을 통해</span>
                <span>
                  친근함과 유연성을 강조하였으며 고객 친화적인 기업의 의지를
                  담았습니다
                </span>
              </p>
              <img src="../imgs/png/logo-li02.png" alt="시그니처로고01" />
            </li>
            <li className="between">
              <p>
                <span>
                  자동차와 쇼핑이 조화를 이루어 다양한 자동차 관련 서비스를
                  제공하는
                </span>
                <span>자동차 쇼핑 통합 플랫폼을 표현하였습니다</span>
              </p>
              <div>
                <img
                  src="../imgs/png/logo-li03-logo.png"
                  alt="시그니처로고02"
                />
                <img
                  src="../imgs/png/logo-li03-vertical.png"
                  alt="시그니처로고03"
                />
              </div>
            </li>
            <li className="start">
              <img src="../imgs/png/logo-li04-02.png" alt="로고설명이미지" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Logo03;
