import { motion, useAnimation } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CarsayoHeader from "../layout/header";
import CarsayoFooter from "../layout/footer";
import Logo01 from "./logo/section01";
import Logo02 from "./logo/section02";
import Logo03 from "./logo/section03";
import Logo04 from "./logo/section04";
import Logo05 from "./logo/section05";
import CarsayoContentWrap from "../layout/content";
import React from "react";
import CarsayoMobileWrap from "../layout/mobileWrap";
import CarsayoMobileLogo from "./logo/logoMobile";
import OSCheck from "../components/OSCheck";
import CarsayoContentWrapV2 from "../layout/content_v2";

const CompanyLogo = () => {
  const currentOS = OSCheck();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {currentOS === "Android" || currentOS === "iOS" ? (
        <CarsayoMobileWrap>
          <CarsayoMobileLogo />
        </CarsayoMobileWrap>
      ) : isMobile ? (
        <CarsayoMobileWrap>
          <CarsayoMobileLogo />
        </CarsayoMobileWrap>
      ) : (
        <CarsayoContentWrapV2>
          <Logo01 />
          <Logo02 />
          <Logo03 />
          <Logo04 />
          <Logo05 />
        </CarsayoContentWrapV2>
      )}
    </>
  );
};
export default CompanyLogo;
