/* eslint-disable import/no-anonymous-default-export */
export default () => {
  // WARNING: For POST requests, body is set to null by browsers.
  var data = JSON.stringify({
    target: "carrot",
    from: `web${window.location.pathname}`,
  });

  var xhr = new XMLHttpRequest();

  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      console.log(this.responseText);
    }
  });

  xhr.open("POST", "https://api.carsayo.net/history/insurance/coocon/click");
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.send(data);
};
