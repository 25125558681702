import { useEffect, useRef, useState } from "react";
import React from "react";
import CarsayoHeaderV2 from "./header_v2";
import ReactFullpage, { fullpageApi } from "@fullpage/react-fullpage";
import CarsayoTopButton from "../components/CarsayoTopButton";
import CarsayoFooter from "./footer";

interface CarsayoContentWrapInterface {
  children: React.ReactNode;
}

const CarsayoContentWrapV2 = ({ children }: CarsayoContentWrapInterface) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const fullpageRef = useRef<fullpageApi | null>(null);

  const goToSlide = (index: number) => {
    if (fullpageRef.current) {
      fullpageRef.current.moveTo(index + 1); // moveTo is 1-based
    }
    return;
  };

  useEffect(() => {
    window.goToSlide = goToSlide;
    window.setSlide = (active: boolean) => {
      if (fullpageRef.current) {
        fullpageRef.current.setAllowScrolling(active);
      }
    };
    return () => {
      window.goToSlide = undefined;
      window.setSlide = undefined;
    };
  }, []);

  return (
    <>
      <CarsayoHeaderV2 currentPage={currentIndex} />

      <ReactFullpage
        credits={{ enabled: true }}
        licenseKey={"YOUR_KEY_HERE"}
        scrollingSpeed={800}
        onLeave={(origin, destination, direction) => {
          const currentIndex = destination.index;
          setCurrentIndex(currentIndex);

          console.log("currentIndex", currentIndex);
        }}
        render={({ fullpageApi }) => {
          fullpageRef.current = fullpageApi;
          return (
            <ReactFullpage.Wrapper>
              {children}
              <CarsayoFooter></CarsayoFooter>
            </ReactFullpage.Wrapper>
          );
        }}
      ></ReactFullpage>

      <CarsayoTopButton
        goTop={() => {
          goToSlide(0);
        }}
        disabled={currentIndex > 1 ? false : true}
        style={{
          opacity: `${currentIndex > 1 ? "1" : "0"}`,
          transition: "opacity .3s ease !important",
          cursor: `${currentIndex > 1 ? "pointer" : "default"}`,
        }}
      />
    </>
  );
};
export default CarsayoContentWrapV2;
