import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Pagination, Autoplay } from "swiper/modules";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const CarsayoMobileLogo = () => {
  // sectioin01 - CI/BI
  const Section01 = () => {
    return (
      <section
        className="m-logo01"
        style={{
          background: "url('../imgs/svg/logo-bg.svg') no-repeat top",
        }}
      >
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title"
        >
          CI / BI
        </VerticalMoveAnimation>
        <OpacityAnimation element="div" delay={0.3} duration={1.5}>
          <img src="../imgs/svg/philosophy-ci.svg" alt="카사요ci" />
          <span>자동차 쇼핑 통합 플랫폼</span>
          <OpacityAnimation element="strong" delay={0.5} duration={1}>
            ALL IN ONE CARSAYO
          </OpacityAnimation>
        </OpacityAnimation>
      </section>
    );
  };
  // section02 - 시그니처 로고
  const Section02 = () => {
    return (
      <section className="m-logo-container">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          시그니처로고
        </VerticalMoveAnimation>
        <OpacityAnimation delay={0.2} duration={1.5} element="div">
          <HorizontalMoveAnimation
            element="p"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            <span>부드러운 곡선 구조의 심플한 이니셜 구성을 통해</span>
            <span>
              친근함과 유연성을 강조하였으며 고객 친화적인 기업의 의지를
              담았습니다.
            </span>
          </HorizontalMoveAnimation>
          <div className="imgBox">
            <span>시그니처 로고</span>
            <img src="../imgs/svg/logo-li02.svg" alt="시그니처로고01" />
          </div>
          <div className="imgBox">
            <span>시그니처 로고 #1</span>
            <img src="../imgs/svg/logo-li03-logo.svg" alt="시그니처로고02" />
          </div>
          <div className="imgBox">
            <span>시그니처 로고 #2</span>
            <img
              src="../imgs/svg/logo-li03-vertical.svg"
              alt="시그니처로고03"
            />
          </div>
          <div className="imgBox">
            <span>로고 모션</span>
            <div className="cover">
              <img src="../imgs/gif/carsayo_splash.gif" alt="카사요스플래시" />
            </div>
          </div>
          <HorizontalMoveAnimation
            element="p"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            <span>
              자동차와 쇼핑이 조화를 이루어 다양한 자동차 관련 서비스를 제공하는
              자동차 쇼핑 통합 플랫폼을 표현하였습니다.
            </span>
          </HorizontalMoveAnimation>
          <div className="imgBox">
            <span>로고 컨셉</span>
            <img src="../imgs/svg/logo-concept.svg" alt="카사요로고 컨셉" />
          </div>
        </OpacityAnimation>
      </section>
    );
  };
  // section03 - 서비스 아이덴티티
  const Section03 = () => {
    return (
      <section className="m-logo-container">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          서비스아이덴티티
        </VerticalMoveAnimation>
        <OpacityAnimation delay={0.2} duration={1.5} element="div">
          <div className="imgBox">
            <span>앱 아이콘</span>
            <div className="icon-cover" style={{ backgroundColor: "#fff" }}>
              <img src="../imgs/svg/symbol-blue.svg" alt="카사요심볼" />
            </div>
            <div className="icon-cover" style={{ backgroundColor: "#7273F9" }}>
              <img src="../imgs/svg/symbol-white.svg" alt="카사요하얀색심볼" />
            </div>
            <div className="icon-cover" style={{ backgroundColor: "#333333" }}>
              <img src="../imgs/svg/symbol-white.svg" alt="카사요하얀색심볼" />
            </div>
          </div>
        </OpacityAnimation>
      </section>
    );
  };
  // section04 - 어디셔널 로고
  const Section04 = () => {
    return (
      <section className="m-logo-container">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          어디셔널로고
        </VerticalMoveAnimation>
        <OpacityAnimation delay={0.2} duration={1.5} element="div">
          <HorizontalMoveAnimation
            element="p"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            <span>
              자동차 생활의 바탕이 되어 사람들의 삶에 더 나은 행복을 만들어
              나아가겠다는 의미를 담아 자동차 모양과 상호를 간결하게
              조합했습니다.
            </span>
          </HorizontalMoveAnimation>
          <div className="imgBox">
            <span>2020년</span>
            <img src="../imgs/svg/logo-previous.svg" alt="카사요구로고" />
          </div>
          <HorizontalMoveAnimation
            element="p"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            <span>
              자동차 생활의 바탕이 되어 사람들의 삶에 더 나은 행복을 함께 만들어
              나아가겠다는 의미를 담아 자동차 모양과 상호를 간결하게
              조합했습니다.
            </span>
          </HorizontalMoveAnimation>
          <div className="imgBox">
            <span>2020년</span>
            <img src="../imgs/svg/symbol-previous.svg" alt="카사요구심볼" />
          </div>
          <HorizontalMoveAnimation
            element="p"
            delay={0.3}
            duration={0.5}
            direction="left"
          >
            <span>
              자동차와 쇼핑백을 조합하여 ‘자동차를 구매한다(산다) 자동차 쇼핑'
              메시지를 직관적으로 표현했습니다.
            </span>
          </HorizontalMoveAnimation>
          <div className="imgBox">
            <span>로고 모션</span>
            <div className="img-swiper">
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                autoplay={{ delay: 5000 }}
                centeredSlides={true}
                loop={true}
                pagination={{ clickable: true }}
                spaceBetween={16}
              >
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_1st.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_2nd.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_3rd.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
                <SwiperSlide>
                  <video
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    webkit-playsinline="true"
                    playsInline
                    loop
                    autoPlay
                    muted
                    preload="auto"
                  >
                    <source
                      src="../videos/Carsayo_splash_4th.mp4"
                      type="video/mp4"
                    />
                  </video>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </OpacityAnimation>
      </section>
    );
  };

  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <Section03 />
      <Section04 />
    </div>
  );
};
export default CarsayoMobileLogo;
