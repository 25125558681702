import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import Expression from "../../components/svg/Expression";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character02 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  return (
    <div className="section character__section02">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CHARACTER CONCEPT
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__contents">
          <div className="left">
            <VerticalMoveAnimation
              element="div"
              className="left__title"
              duration={0.5}
              direction="top"
            >
              <p>#1 HEROES 2025</p>
              <h3>
                <span>카키</span>
              </h3>
            </VerticalMoveAnimation>
            <div className="left__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>
                  실수가 없고 깔끔한 일처리를 자랑하지만 그만큼 예민하기도 하고
                </span>
                <span>엄격한 말투와 모습을 보이기도 한다.</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>특히 싫어하는 것은 계획이 틀어지는 것.</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>하지만 말투와 다르게 카키의 마음은 의외로 연약하다!</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  본격적으로 계산에 들어가기 전 안경을 치켜세우는 버릇이 있다.
                </span>
              </HorizontalMoveAnimation>
            </div>
            <Expression textColor="#fff" clickEvent={setPopupOpen} />
            <div className="character-clip-box">
              <img
                src="../imgs/png/character-clip02.png"
                alt="캐릭터 클립 이미지2"
              />
              <span className="clip-bg">
                <img src="../imgs/png/clip.png" alt="클립이미지" />
              </span>
            </div>
          </div>
          <div className="right">
            <img
              src="../imgs/svg/character-section02-img.svg"
              alt="카키 이미지"
            />
            <div className="character-nameTag">
              <div>
                <p>분석적이며 깐깐한 성격으로 계산 능력까지 뛰어난</p>
                <h5>카키</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character02;
