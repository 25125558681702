import { motion } from "framer-motion";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

interface MainInterface {
  currentOS: string;
}

const MainSection09 = ({ currentOS }: MainInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="section">
      <div className="main-section09">
        <img
          src="../imgs/svg/mainShoppingBg.svg"
          alt="자동차쇼핑통합플랫폼카사요배경이미지"
          className={isAnimated ? "animation" : ""}
        />
        <div className="left">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.2, duration: 0.5 },
            }}
            onAnimationStart={() => setAnimated(true)}
            onAnimationIteration={() => setAnimated(false)}
          >
            <img src="../imgs/svg/groupApps.svg" alt="" />
          </motion.div>
        </div>
        <div className="right">
          <div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.3 },
              }}
            >
              <h3>
                <span>자동차 쇼핑 통합 플랫폼</span> <span>카사요</span>
              </h3>
            </motion.div>
          </div>
          <div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.3, duration: 0.3 },
              }}
            >
              <span>
                신차 구매와 내 차 팔기, 자동차 금융, 자동차 다이렉트 보험까지
              </span>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.4, duration: 0.3 },
              }}
            >
              <span>
                자동차 관련 모든 서비스를 전문적으로 제공하는 국내에서 유일한
                플랫폼입니다.
              </span>
            </motion.div>
          </div>
          <CarsayoButton
            bgColor={"#4F9CF9"}
            onIcon="../imgs/svg/rightArrow.svg"
            iconSize=".875rem"
            padding="1.25rem 2.5rem"
            onClick={() => {
              currentOS === "iOS" || currentOS === "Mac"
                ? window.open(
                    `https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`
                  )
                : window.open(
                    `https://play.google.com/store/apps/details?id=com.ksapp.carsayo`
                  );
            }}
          >
            확인하기
          </CarsayoButton>
        </div>
      </div>
    </div>
  );
};

export default MainSection09;
