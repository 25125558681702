import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";

/* eslint-disable react/jsx-pascal-case */
const CarsayoMobileIntroduction = () => {
  // sectioin01 - CEO 소개
  const Section01 = () => {
    return (
      <section className="m-introduction01">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title"
        >
          CEO소개
        </VerticalMoveAnimation>
        <OpacityAnimation duration={1} element="p" delay={0.2}>
          <span>1999년부터 26년간 금융 무사고</span>
          <span>국산차 및 수입차 4대 브랜드</span>
          <span>최고 세일즈 기록</span>
        </OpacityAnimation>
        <div>
          <OpacityAnimation duration={1} element="strong" delay={0.4}>
            ALL IN ONE CARSAYO
          </OpacityAnimation>
          <OpacityAnimation duration={1} element="p" delay={0.6}>
            <span>고객의 안전과 편의를 최우선으로 </span>
            <span>최적의 조건과 혜택을 제시했습니다.</span>
          </OpacityAnimation>
        </div>
      </section>
    );
  };
  // section02 - CEO 메시지
  const Section02 = () => {
    return (
      <section className="m-introduction02">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          CEO메시지
        </VerticalMoveAnimation>
        <OpacityAnimation duration={1.5} element="div" delay={0.3}>
          <p>안녕하십니까?</p>
          <p>
            고객님의 편의를 극대화하기 위해 경험을 바탕으로 다양한 자동차 관련
            서비스를 모은 [카사요]를 출시했습니다.
          </p>
          <p>
            [카사요]는 국내 유일의 자동차 쇼핑 통합 플랫폼으로, 업계
            선도자로서의 자부심과 책임감을 가지고 있습니다.
          </p>
          <p>
            우리는 신뢰할 수 있는 자동차 시장을 위해 자동차 구매, 판매 및 금융의
            고질적인 문제를 해결하고 고객을 보호하기 위해 다양한 장치를 마련하고
            있습니다.
          </p>
          <p>
            ESG(환경, 사회, 지배구조) 경영을 통해 지속 가능한 발전을 추구하며
            투명하고 윤리적인 기업 경영을 실천하고 있습니다.
          </p>
          <p>
            또 소비자중심경영(CCM)을 수행하여 고객과 양방향으로 소통하고,
            지속적인 서비스 개선과 혁신으로 사회적 책임을 다하고 있습니다.
          </p>
          <p>
            앞으로도 카사요는 고객의 권익을 보호하고 고객과 신뢰를 쌓아가는
            기업이 될 것을 약속드립니다.
          </p>
          <p>여러분의 지속적인 관심과 성원을 부탁드립니다.</p>
          <p>감사합니다.</p>
        </OpacityAnimation>
        <OpacityAnimation duration={1} element="div" delay={0.2}>
          <span>카사요(주) 대표이사</span>
          <strong>최 용민</strong>
        </OpacityAnimation>
      </section>
    );
  };
  // section03 - 주요 경력
  const Section03 = () => {
    return (
      <section className="m-subPage-content">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          주요경력
        </VerticalMoveAnimation>
        <div>
          <div
            style={{
              background: "url(../imgs/svg/carrier-bg.svg) no-repeat center",
            }}
          >
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
              className="key-point"
            >
              <span>KEY POINT</span>
              <h3>CARRIER</h3>
            </HorizontalMoveAnimation>
            <OpacityAnimation
              element="div"
              delay={0.2}
              duration={1.5}
              className="top-box"
            >
              <span>국내·수입 자동차 판매 통합 시스템 총괄 컨설턴트</span>
              <span>(신차, 중고차, 수입·수출, 금융, 보험, A/S)</span>
            </OpacityAnimation>
          </div>
          <VerticalMoveAnimation
            element="ul"
            delay={0.2}
            duration={0.5}
            direction="top"
          >
            <li>
              <p>
                <span>수입차 브랜드 대상 </span>
                <span>세일즈 / 마케팅 / 직원 서비스 컨설팅 및 교육</span>
              </p>
            </li>
            <li>
              <p>
                <span>구 LG카드 (현 신한카드) 리스총괄 컨설팅 등</span>
                <span>자동차 금융 설계 최고 경력</span>
              </p>
            </li>
            <li>
              <p>
                <span>서비스 TOP 브랜드(LEXUS)</span>
                <span>4년 연속 고객 관리 최고 평점 기록</span>
              </p>
            </li>
          </VerticalMoveAnimation>
        </div>
      </section>
    );
  };
  // section03 - 주요 기록
  const Section03_02 = () => {
    return (
      <section className="m-subPage-content">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          주요기록
        </VerticalMoveAnimation>
        <div>
          <div
            style={{
              background:
                "url(../imgs/svg/introduction-career02.svg) no-repeat center",
              color: "#111",
            }}
          >
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
              className="key-point"
            >
              <span>KEY POINT</span>
              <h3>RECORD</h3>
            </HorizontalMoveAnimation>
            <OpacityAnimation
              element="div"
              delay={0.2}
              duration={1.5}
              className="top-box colorBlack"
            >
              <span>저서 ‘세일즈 신화를 만드는 힘'</span>
              <span>한국 경제 신문 출판</span>
            </OpacityAnimation>
          </div>
          <VerticalMoveAnimation
            element="ul"
            delay={0.2}
            duration={0.5}
            direction="top"
          >
            <li>
              <p>
                <span>세계 3대 인명 사전 ‘ Marquis Who's Who’</span>
                <span>‘marketing professional consultant’로 등재</span>
              </p>
            </li>
            <li>
              <p>
                <span>세계 3대 인명 사전</span>
                <span>‘IBC, International Biographical Centre’</span>
                <span>‘Leading Professionals of the World’로 등재</span>
              </p>
            </li>
            <li>
              <p>
                <span>IBK 중소기업 CEO Report 칼럼 기고</span>
              </p>
            </li>
            <li>
              <p>
                <span>[2013 한국 현대인물열전 33선]</span>
                <span>한국인물연구원 (한국현대인물열전편찬회)</span>
              </p>
            </li>
            <li>
              <p>
                <span>대한민국 세일즈 최고 기록(한국 기록원) </span>
                <span>각 세일즈 분야 대상 수상 기록 최다 보유자</span>
              </p>
            </li>
          </VerticalMoveAnimation>
        </div>
      </section>
    );
  };
  // section03 - 수상 기록
  const Section03_03 = () => {
    return (
      <section className="m-subPage-content">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          수상기록
        </VerticalMoveAnimation>
        <div>
          <div
            style={{
              background: "url(../imgs/svg/winning-bg.svg) no-repeat center",
            }}
          >
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
              className="key-point"
            >
              <span>KEY POINT</span>
              <h3>THE KING</h3>
            </HorizontalMoveAnimation>
            <OpacityAnimation
              element="div"
              delay={0.2}
              duration={1.5}
              className="top-box"
            >
              <span>2004년 ~ 2006년 LEXUS 판매왕</span>
              <span>2010년 INSURANCE MDRT</span>
            </OpacityAnimation>
          </div>
          <VerticalMoveAnimation
            element="ul"
            delay={0.2}
            duration={0.5}
            direction="top"
          >
            <li>
              <p>
                <span>2003년 GM(Cadilac, Saab) 판매왕</span>
              </p>
            </li>
            <li>
              <p>
                <span>2002년 BMW 판매왕</span>
              </p>
            </li>
            <li>
              <p>
                <span>2000년 ~ 2001년 DAEWOO 판매왕</span>
              </p>
            </li>
          </VerticalMoveAnimation>
        </div>
      </section>
    );
  };
  // // section03 - 수상 기록
  // const Section03_04 = () => {
  //   return (
  //     <section className="m-subPage-content">
  //       <h2 className="subPage-title02">수상기록</h2>
  //       <div>
  //         <div
  //           style={{
  //             background:
  //               "url(../imgs/png/introduction-career04.png) no-repeat center",
  //           }}
  //         >
  //           <div>
  //             <span>KEY POINT</span>
  //             <h3>THE KING</h3>
  //           </div>
  //           <div>
  //             <span>2004년 ~ 2006년 LEXUS 판매왕</span>
  //             <span>2010년 INSURANCE MDRT</span>
  //           </div>
  //         </div>
  //         <ul>
  //           <li>
  //             <p>
  //               <span>2004년 ~ 2006년 LEXUS 판매왕</span>
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               <span>2010년 INSURANCE MDRT</span>
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               <span>2003년 GM(Cadilac, Saab) 판매왕</span>
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               <span>2002년 BMW 판매왕</span>
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               <span>2000년 ~ 2001년 DAEWOO 판매왕</span>
  //             </p>
  //           </li>
  //         </ul>
  //       </div>
  //     </section>
  //   );
  // };
  // section03 - 보도 자료
  const Section03_05 = () => {
    return (
      <section className="m-subPage-content">
        <VerticalMoveAnimation
          element="h2"
          delay={0.2}
          duration={0.5}
          direction="top"
          className="subPage-title02"
        >
          보도자료
        </VerticalMoveAnimation>
        <div>
          <div
            style={{
              background: "url(../imgs/svg/news-bg.svg) no-repeat center",
            }}
          >
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
              className="key-point"
            >
              <span>KEY POINT</span>
              <h3>NEWS & MEDIA</h3>
            </HorizontalMoveAnimation>
            <OpacityAnimation
              element="div"
              delay={0.2}
              duration={1.5}
              className="top-box"
            >
              <span>YTN 방송 방영 : 판매왕의 비결 ‘ 사람이 재산'</span>
              <span>SBS 방송 방영 : 수입차 판매왕이 말하다</span>
            </OpacityAnimation>
          </div>
          <VerticalMoveAnimation
            element="ul"
            delay={0.2}
            duration={0.5}
            direction="top"
          >
            <li>
              <p>
                <span>한국 경제 지면 & 온라인 보도</span>
                <span>실적보다 중요한건 고객과의 인연</span>
              </p>
            </li>
            <li>
              <p>
                <span>매일경제 지면 & 온라인 보도</span>
                <span>3일에 1대 판매, 수입차 판매왕들의 비법은?</span>
              </p>
            </li>
            <li>
              <p>
                <span>교원 그룹</span>
                <span>세일즈는 처음부터 끝까지 사람입니다</span>
              </p>
            </li>
          </VerticalMoveAnimation>
        </div>
      </section>
    );
  };
  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <Section03 />
      <Section03_02 />
      <Section03_03 />
      {/* <Section03_04 /> */}
      <Section03_05 />
    </div>
  );
};
export default CarsayoMobileIntroduction;
