import { motion } from "framer-motion";
import { useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import OpacityAnimation from "../../../utils/opacityAnimation";
import React from "react";

const Philosophy02 = () => {
  return (
    <div className="section philosophy__section02">
      <div className="company__section__title">
        <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
          BUSINESS
          <span>사업영역</span>
        </VerticalMoveAnimation>
      </div>
      <div className="philosophy__section02__content">
        <HorizontalMoveAnimation
          element="div"
          delay={0.2}
          duration={0.5}
          direction="left"
        >
          <p>자동차 쇼핑 통합 플랫폼</p>
          <HorizontalMoveAnimation
            element="h4"
            delay={0.4}
            duration={0.5}
            direction="left"
          >
            ALL IN ONE CARSAYO
          </HorizontalMoveAnimation>
        </HorizontalMoveAnimation>
        <ul>
          <li className="start">
            <h5>MISSON</h5>
            <p>NEVER-ENDING GROWTH</p>
          </li>
          <li className="between">
            <h5>고객의 경험을 풍요롭게</h5>
            <p>
              <span>안전하고 편리한 자동차 생활 서비스를 제공하여</span>
              <span>
                고객이 합리적으로 선택할 수 있는 국민 기업으로 성장하고
                있습니다.
              </span>
            </p>
          </li>
          <li className="between">
            <h5>지속 발전 가능한 바른 경영</h5>
            <p>
              <span>
                혁신적인 기술과 솔루션을 제공하는 자동차 업계 리더로서
              </span>
              <span>바른 경영을 통해 지속 가능한 미래를 만들겠습니다.</span>
            </p>
          </li>
          <li className="start">
            <h5>VISION</h5>
            <p>NEVER-ENDING GROWTH</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Philosophy02;
