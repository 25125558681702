interface CarsayoTopButtonInterface {
  style?: React.CSSProperties;
  disabled?: boolean;
}

const CarsayoMobileTopButton = ({
  style,
  disabled,
}: CarsayoTopButtonInterface) => {
  const handleClickEvents = {
    goTop: () => {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  };
  return (
    <button
      className="goTopMobile"
      onClick={handleClickEvents.goTop}
      style={style}
      disabled={disabled}
    >
      <img src="../imgs/svg/goTopArrow.svg" alt="위쪽화살표" />
    </button>
  );
};

export default CarsayoMobileTopButton;
